import { LoginForm } from "@features/auth";
import React from "react";

interface loginProps {}

const login: React.FC<loginProps> = () => (
    <>
        <LoginForm />
    </>
);

export default login;
